import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { cleannerError } from "../../../../../utils/utils";
import { SocialMediaFormData } from "../../../../../utils/api/config/config.type";
import { useUpdateSocialMediaMutation } from "../../../../../utils/api/config/config.api";
import { QueryError } from "../../../../../utils/type";

function useSocialMediaForm() {
	const validationSchema = yup.object().shape({
		facebook: yup.string().url().required().label("Facebook"),
		instagram: yup.string().url().required().label("Instagram"),
		linkedin: yup.string().url().required().label("Linkedin"),
		twitter: yup.string().url().required().label("Twitter"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
	} = useForm<SocialMediaFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [updateSocialMedia, { isLoading, isSuccess, error, isError }] =
		useUpdateSocialMediaMutation();

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: `Modifié avec succès`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			});
		}
		const err = error as QueryError;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message ? err?.data?.message : "Une erreur a survenu",
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const onSubmit = (data: SocialMediaFormData) => {
		console.log(data);
		updateSocialMedia({ slug: data?.slug, data });
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading: isLoading,
	};
}
export default useSocialMediaForm;
