import React, { useEffect } from "react";
import { useGetSocialMediaQuery } from "../../../../utils/api/config/config.api";
import { BtnSubmit } from "../../../common/Button";
import { AdminInput } from "../../../common/Input";
import useSocialMediaForm from "./hooks/useSocialMediaForm";

const SocialMedia = () => {
	const { data } = useGetSocialMediaQuery();
	const { register, onSubmit, errors, isLoading, setValue } = useSocialMediaForm();
	useEffect(() => {
		if (data) {
			setValue("slug", data?.slug);
			setValue("facebook", data?.facebook);
			setValue("linkedin", data?.linkedin);
			setValue("instagram", data?.instagram);
			setValue("twitter", data?.twitter);
		}
	}, [data]);
	return (
		<div className="tab-content-admin px-3 py-3">
			<form onSubmit={onSubmit}>
				<div className="mt-3 row">
					<div className="col-md-6 mb-3">
						<AdminInput
							label="Facebook"
							id="facebook"
							type="text"
							placeholder="Lien"
							{...register("facebook")}
							error={errors?.facebook}
							inputMode="text"
						/>
					</div>
					<div className="col-md-6 mb-3">
						<AdminInput
							label="Instagram"
							id="instagram"
							type="text"
							placeholder="Lien"
							{...register("instagram")}
							error={errors?.instagram}
							inputMode="text"
						/>
					</div>
					<div className="col-md-6 mb-3">
						<AdminInput
							label="Linkedin"
							id="linkedin"
							type="text"
							placeholder="Lien"
							{...register("linkedin")}
							error={errors?.linkedin}
							inputMode="text"
						/>
					</div>
					<div className="col-md-6 mb-3">
						<AdminInput
							label="Twitter"
							id="twitter"
							type="text"
							placeholder="Lien"
							{...register("twitter")}
							error={errors?.twitter}
							inputMode="text"
						/>
					</div>
				</div>
				<div className="px-2 flex-r col-md-4 offset-md-8 gap-3 py-3">
					<BtnSubmit
						label="Modifier"
						isLoading={isLoading}
					/>
				</div>
			</form>
		</div>
	);
};

export default SocialMedia;
