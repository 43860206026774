import React from "react";
import "./FooterLandingpage.css";
import Logo from "../../../assets/landingpage/logo-footer.png";
import PlayStore from "../../../assets/landingpage/play_store.png";
import AppStore from "../../../assets/landingpage/app_store.png";
import { GrLocation } from "react-icons/gr";
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineMail } from "react-icons/md";
import { useGetSocialMediaVisitorQuery } from "../../../utils/api/config/config.api";
import { NavLink } from "react-router-dom";

const FooterLandingpage = () => {
  var d = new Date();
  var date = d.getFullYear();
  const { data } = useGetSocialMediaVisitorQuery();
  return (
    <footer className="footer-landingpage">
      <div className="landingpage-crypta-container">
        <div className="row row-footer align-items-start">
          <div className="col-md-3 mb-3 flex-c justify-content-md-start">
            <div className="content-col-logo-footer">
              <img src={Logo} alt="Logo" className="logo-footer" />
            </div>
          </div>
          <div className="col-md-3 mb-3">
            <div className="footer-flex">
              <div className="content-contact-footer">
                <div className="contact-app-footer-item mb-2">
                  <GrLocation style={{ color: "#000000" }} />
                  <span className="text-contact-footer-item">
                    GADAYE, Prolongement VDN, Lot N 1, Dakar, Sénégal
                  </span>
                </div>
                <div className="contact-app-footer-item mb-2">
                  <FiPhoneCall style={{ color: "#000000" }} />
                  <span className="text-contact-footer-item">
                    <a href="tel:+221 77 299 0065" className="no-link">
                      +221 77 299 0065
                    </a>
                  </span>
                </div>
                <div className="contact-app-footer-item mb-2">
                  <MdOutlineMail style={{ color: "#000000" }} />
                  <span className="text-contact-footer-item">
                    <a href="mailto:contact@kivu.sn" className="no-link">
                      contact@kivu.sn
                    </a>
                  </span>
                </div>
                <div className="content-text-copyright pt-4">
                  <p className="text-copyright mb-0">
                    (c) Copyright{" "}
                    <span style={{ fontWeight: 600 }}>kivu {date}</span>. Tout
                    droit réservé
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-3">
            <div className="footer-flex">
              <div className="content-contact-footer">
                <div className="contact-app-footer-item mb-3">
                  <span className="text-contact-footer-item">
                    <NavLink
                      to="/condition-generale-d-utilisation"
                      className="no-link"
                    >
                      Conditions générales d'utilisation
                    </NavLink>
                  </span>
                </div>
                <div className="contact-app-footer-item mb-3">
                  <span className="text-contact-footer-item">
                    <NavLink
                      to="/politique-de-confidentialite"
                      className="no-link"
                    >
                      Politiques de confidentialité
                    </NavLink>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-3">
            <div className="footer-flex flex-column w-100">
              <div className="content-img-download-app-title-black ms-auto me-auto">
                Téléchargez notre application
              </div>
              <div className="content-img-download-app-item-container">
                <div className="content-img-download-app-item">
                  <a
                    href="#"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="no-link"
                  >
                    <img
                      src={AppStore}
                      alt="App store"
                      className="img-download-app-item"
                    />
                  </a>
                </div>
                <div className="content-img-download-app-item">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.crypta.prod"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="no-link"
                  >
                    <img
                      src={PlayStore}
                      alt="Play store"
                      className="img-download-app-item"
                    />
                  </a>
                </div>
              </div>
              <div className="container-image-sociaux mt-4 pt-3 w-100">
                <div className="wrapper-social-media">
                  {data?.facebook && (
                    <a href={data.facebook} className="button">
                      <div className="icon">
                        <i className="fab fa-facebook"></i>
                      </div>
                    </a>
                  )}

                  {data?.twitter && (
                    <a href={data.twitter} className="button">
                      <div className="icon">
                        <i className="fab fa-twitter"></i>
                      </div>
                    </a>
                  )}

                  {data?.linkedin && (
                    <a href={data.linkedin} className="button">
                      <div className="icon">
                        <i className="fab fa-linkedin"></i>
                      </div>
                    </a>
                  )}
                  {data?.instagram && (
                    <a href={data.instagram} className="button">
                      <div className="icon">
                        <i className="fab fa-instagram"></i>
                      </div>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterLandingpage;
