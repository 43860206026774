/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError, onHide } from "../../../../../utils/utils";
import { toast } from "react-hot-toast";
import { 
    IScreen, 
    ScreenFormData 
} from "../../../../../utils/api/screen/screen.type";
import { useAddOrUpdateScreenMutation } from "../../../../../utils/api/screen/screen.api";


function useCrudScreen(item?: IScreen, resetItem?: () => void) {

	const validationSchema = yup.object().shape({
        hasEdit: yup.boolean().default(false),
        image: yup
        	.mixed()
            .when("hasEdit", {
                is: false,
                then: () => yup.mixed().required().nullable().label("L'image"),
                otherwise: () => yup.mixed().nullable().label("L'image"),
            })
            .nullable(),
        titre: yup.string().required().label("Titre"),
        desription: yup.string().required().label("Description"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
		setError
	} = useForm<ScreenFormData>({
		resolver: yupResolver(validationSchema),
        defaultValues: {
            hasEdit: false,
        },
	});

	const [description, setDescription] = useState<string>();
    const [image, setImage] = useState<any>(null);
	const [addOrUpdateItem, { isLoading }] = useAddOrUpdateScreenMutation();

	const handleChangeDescription = (value: string) => {
		setDescription(value);
		setValue("desription", value);
	};
    const handleChangeScreenImage = (e: any) => {
        let file = e?.target?.files[0];
        if (file) {
            setImage(URL.createObjectURL(file));
            setValue("image", file);
        }
    };

	// useEffect(() => {
	// 	cleannerError(errors, clearErrors);
	// }, [errors]);

	useEffect(() => {
		if (item?.id) {
            let hasEdit: boolean = true;
            setValue("hasEdit", hasEdit);
			setValue("titre", item?.titre);
			setValue("desription", item?.desription);
			setDescription(item?.desription);
		} else {
            let hasEdit: boolean = false;
            setValue("hasEdit", hasEdit);
        }
	}, [item]);

	const onSubmit = async (data: ScreenFormData) => {
		if (!item && !data?.image) {
			return setError("image", { message: "Image est un champ obligatoire" });
		}
		console.log("data", data);
		const fd = new FormData();
		for (let key of Object.keys(data)) {
			const value = data[key];
			fd.append(key, value);
		}
		const res = await addOrUpdateItem({
			slug: item?.slug,
			data: fd,
		});
		if ("data" in res) {
			toast.success(
				!item
					? "Écran ajouté avec succès"
					: "L'écran a été modifié avec succès !"
			);
			reset();
			onHide("AddOrEditScreenModal");
			setImage(undefined);
			resetItem && resetItem();
		}
		if ("error" in res) {
			const err = res.error as any;

			let message = err?.data?.message
				? err?.data?.message
				: `Une erreur a survenue lors de ${
						item ? "la modification" : "l'ajout"
				  }`;
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
        image,
        setImage,
        handleChangeScreenImage,
		description,
        setDescription,
		handleChangeDescription,
		reset,
	};
}

export default useCrudScreen;
