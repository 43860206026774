/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
	checkIfIsImage,
	cleannerError,
	convertFileSizeByteToMegaByte,
	onHide,
} from "../../../../../utils/utils";
import { toast } from "react-hot-toast";
import { ISlide, SlideFormData } from "../../../../../utils/api/slide/slide.type";
import { useAddOrUpdateSlideMutation } from "../../../../../utils/api/slide/slide.api";

function useCrudSlide(item?: ISlide, resetItem?: () => void) {
	const validationSchema = yup.object().shape({
		contenu: yup.string().required().label("Contenu"),
		lien: yup.string().required().label("Lien"),
		position: yup.string().required().label("Position"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
		setError,
	} = useForm<SlideFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [image, setImage] = useState<File>();
	const [addOrUpdateitem, { isLoading }] = useAddOrUpdateSlideMutation();

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (item?.id) {
			setValue("lien", item?.lien);
			setValue("contenu", item?.contenu);
			setValue("position", item?.position);
		}
	}, [item]);

	const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			let file = e.target.files[0];
			const sizeInBytes = file.size;
			const size = convertFileSizeByteToMegaByte(sizeInBytes);
			console.log(file.name);
			if (size > 10) {
				return setError("image", { message: "Fichier trop volumineux" });
			}
			let isImage = checkIfIsImage(file.type);
			if (isImage) {
				setImage(file);
			} else {
				setImage(undefined);
			}
			setValue("image", file);
		}
	};

	const onSubmit = async (data: SlideFormData) => {
		if (!item && !data?.image) {
			return setError("image", { message: "Image est un champ obligatoire" });
		}
		console.log("data", data);
		const fd = new FormData();
		for (let key of Object.keys(data)) {
			const value = data[key];
			fd.append(key, value);
		}
		const res = await addOrUpdateitem({
			slug: item?.slug,
			data: fd,
		});
		if ("data" in res) {
			toast.success(
				!item
					? "Slide ajoutée avec succès"
					: "La slide a été modifiée avec succès !"
			);
			reset();
			onHide("AddSlideModal");
			setImage(undefined);
			resetItem && resetItem();
		}
		if ("error" in res) {
			const err = res.error as any;

			let message = err?.data?.message
				? err?.data?.message
				: `Une erreur a survenue lors de ${item ? "la modification" : "l'ajout"}`;
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		image,
		handleChangeImage,
		setImage,
		reset,
	};
}

export default useCrudSlide;
