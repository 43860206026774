import React from "react";
import CloseModalBtn from "../../shared/CloseModalBtn";
import { AdminInput, InputFile } from "../../common/Input";
import { BtnSubmit } from "../../common/Button";
import useCrudSlide from "../../TableauDebord/Admin/ParametreAdmin/hooks/useCrudSlide";
import { FormError } from "../../common/Input";
import { ISlide } from "../../../utils/api/slide/slide.type";
import { PreviewImage, ViewUniqueImage } from "../../common/PreviewImage";
import { checkIfIsImage } from "../../../utils/utils";

const AddSlideModal = ({ item, resetItem }: { item?: ISlide; resetItem: () => void }) => {
	const { register, onSubmit, errors, isLoading, image, handleChangeImage, setImage, reset } =
		useCrudSlide(item, resetItem);
	const resetAll = () => {
		resetItem();
		setImage(undefined);
		reset();
	};
	return (
		<div className="modal-dialog modal-lg">
			<div className="modal-content">
				<div className="modal-header py-2 flex-m">
					<h5 className="admin-modal-title text-start">
						{item ? "Modifier la" : "Ajouter une"} slide
					</h5>
					<CloseModalBtn onClick={resetAll} />
				</div>
				<div className="modal-body">
					<div className="container-form">
						<form onSubmit={onSubmit}>
							<div className="row">
								<div className="col-md-12">
									<InputFile
										label="Fichier (image/video)"
										id="image"
										type="file"
										error={
											errors?.image
										}
										required
										accept="image/*,video/mp4,video/x-m4v,video/avi, video/*"
										onChange={
											handleChangeImage
										}
										file={image}
									/>
									{image && (
										<PreviewImage
											image={image}
										/>
									)}
									{item?.image &&
										checkIfIsImage(
											item.image
										) && (
											<ViewUniqueImage
												image={
													item?.image
												}
											/>
										)}
								</div>
								<div className="col-md-12">
									<AdminInput
										label="Lien"
										id="prenom"
										type="text"
										placeholder="Lien"
										{...register(
											"lien"
										)}
										error={errors?.lien}
										required
										inputMode="text"
									/>
								</div>
								<div className="col-md-12">
									<AdminInput
										label="Position"
										id="position"
										type="text"
										placeholder="Position"
										{...register(
											"position"
										)}
										error={
											errors?.position
										}
										required
										inputMode="text"
									/>
								</div>
								<div className="col-md-12">
									<label
										htmlFor="contenu"
										className="form-label form-label-modal-custom"
									>
										Contenu
										<span
											className="text-danger"
											style={{
												fontSize: 24,
											}}
										>
											*
										</span>
									</label>
									<textarea
										id="contenu"
										className="form-control"
										{...register(
											"contenu"
										)}
										style={{
											minHeight:
												"150px",
											maxHeight:
												"150px",
										}}
									/>
									<FormError
										error={
											errors?.contenu
										}
									/>
								</div>
							</div>
							<div className="container-btn-modal d-flex justify-content-end gap-3 mt-4">
								<button
									type="button"
									className="btn btn-cancelled"
									data-bs-dismiss="modal"
									onClick={resetAll}
								>
									Fermer
								</button>
								<BtnSubmit
									isLoading={isLoading}
									label={
										item
											? "Modifier"
											: "Ajouter"
									}
									style={{
										width: "inherit",
									}}
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddSlideModal;
