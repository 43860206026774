import moment from "moment";
import React from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import VolkenoSkeleton from "volkeno-react-skeleton";
import { IContact } from "../../../../utils/api/contact/contact.type";
import {
	createMarkup,
	formatMessageStatus,
	formatMessageStatusClass,
} from "../../../../utils/utils";
import { Link } from "react-router-dom";

const MessageContactItem = ({
	item,
	getItem,
}: {
	item: IContact;
	getItem: (item: IContact) => void;
}) => {
	return (
		<div className="mb-3">
			<div className="px-2 py-3 admin-message-contact-card mb-2 flex-m gap-1">
				<div className="row w-100 align-items-center">
					<div className="col-md-7">
						<span className="modal-title admin-modal-title text-start fs-18 m-0 lh-28 me-2">
							Sujet:
						</span>
						<Link
							className="admin-message-contact-card-description d-flex btn p-0"
							style={{
								textOverflow: "ellipsis",
							}}
							to={item?.slug}
							state={item}
						>
							{item?.subject?.slice(0, 50)}
							{item?.subject && "..."}
						</Link>
						<p className="admin-message-contact-card-frequence fz-14 mb-0">
							{moment(item?.created_at).format("DD/MM/YYYY à HH:MM")}
						</p>
					</div>
					<div className="col-md-2">
						<button
							className={`btn message-btn ${formatMessageStatusClass(
								item?.status
							)}`}
							data-bs-toggle="modal"
							data-bs-target="#ChangeStatusMessageModal"
							aria-expanded="false"
							onClick={() => getItem(item)}
						>
							{formatMessageStatus(item?.status)}
						</button>
					</div>
					<div className="col-md-3 flex-r">
						<p className="admin-message-contact-card-description mb-0">
							{item?.name}
						</p>
					</div>
				</div>
				<button
					className="no-style-btn accordion-button"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target={`#responseContact1${item?.slug}`}
					aria-expanded="false"
					aria-controls="responseContact1"
				>
					<MdKeyboardArrowDown />
				</button>
			</div>
			<div className="collapse" id={`responseContact1${item?.slug}`}>
				<span className="modal-title admin-modal-title text-start fs-18 m-0 lh-28 me-2">
					Message:
				</span>
				<div
					className="card card-body admin-content-message mb-1"
					dangerouslySetInnerHTML={createMarkup(item?.message)}
				/>
			</div>
		</div>
	);
};

export function ContactMessageItemSkeleton() {
	return (
		<div className="mb-3">
			<div className="px-2 py-3 admin-message-contact-card mb-2 flex-m gap-1">
				<div className="row w-100 align-items-center">
					<div className="col-md-7">
						<VolkenoSkeleton variant="rect" width="100%" height={10} />
						<VolkenoSkeleton variant="rect" width="100%" height={10} />
					</div>
					<div className="col-md-2">
						<VolkenoSkeleton variant="rect" width="100%" height={10} />
					</div>
					<div className="col-md-3">
						<VolkenoSkeleton variant="rect" width="100%" height={10} />
					</div>
				</div>
			</div>
		</div>
	);
}
export default MessageContactItem;
