import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useDeleteWithCode, useItem, usePagination } from "../../../../utils/hooks";
import TableSkeleton from "../../../common/TableSkeleton";
import { formatMontant, formatOffreFrequence, isShowPagination } from "../../../../utils/utils";
import { CustomPagination } from "../../../common/CustomPagination";
import { ButtonDelete, ButtonEdit, ButtonView, Spinner } from "../../../common/Button";
import {
	useDeleteOffreMutation,
	useGetOffresQuery,
	useLazySendOfferDeleteCodeQuery,
} from "../../../../utils/api/offres/offres.api";
import DetailOffreModal from "../../../modal/admin/DetailOffreModal";
import { IOffres } from "../../../../utils/api/offres/offres.type";
import AddOffreModal from "../../../modal/admin/AddOffreModal";
import { useOffresListener } from "../../../../utils/pusher";

export function DeleteOffre({ item }: { item: IOffres }) {
	const [sendCode, { isLoading }] = useLazySendOfferDeleteCodeQuery();

	const [deleteItem, { isSuccess, isError, error }] =
		useDeleteOffreMutation();
	const onDelete = useDeleteWithCode<IOffres>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		sendCode,
		title: "Suppression d'une offre",
		successMessage: "Offre supprimée avec succès !",
	});
	if (isLoading) return <Spinner />;
	return <ButtonDelete onClick={onDelete} />;
}

function ListeOffresTable() {
	useOffresListener();
	const { page, setPage, limit, setPerPage } = usePagination(10);
	const { data, isLoading } = useGetOffresQuery({
		page,
		limit,
	});

	const { item, getItem, resetItem } = useItem<IOffres>();

	const actionFormatter = (cell: string, row: IOffres) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<ButtonView
						data-bs-toggle="modal"
						data-bs-target="#DetailOffreModal"
						onClick={() => getItem(row)}
					/>
					<ButtonEdit
						data-bs-toggle="modal"
						data-bs-target="#AddOffreModal"
						onClick={() => getItem(row)}
					/>
					<DeleteOffre item={row} />
				</div>
			</>
		);
	};

	const columns = [
		{
			dataField: "title",
			text: "Titre",
		},
		{
			dataField: "frequence",
			text: "Fréquence",
			formatter: (cell: string) => formatOffreFrequence(cell),
		},
		{
			dataField: "price",
			text: "Prix",
			formatter: (cell) => formatMontant(cell),
		},
		{
			dataField: "taux_hospitalisation",
			text: "Taux hospitalisation",
			formatter: (cell) => (
				<span className="ps-5">{Number(cell)}%</span>
			),
		},
		{
			dataField: "id",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: IOffres) =>
				actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() =>
							"Aucune donnée trouvée"
						}
					/>
					{isShowPagination(data?.count || 0) && (
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={(page, limit) => {
								setPerPage(limit);
								setPage(page);
							}}
							perPage={limit}
						/>
					)}
				</>
			)}
			<div
				className="modal fade"
				id="DetailOffreModal"
				aria-labelledby="DetailOffreModalLabel"
				aria-hidden="true"
			>
				<DetailOffreModal item={item} />
			</div>
			<div
				className="modal fade"
				id="AddOffreModal"
				aria-labelledby="AddOffreModalLabel"
				aria-hidden="true"
			>
				<AddOffreModal item={item} resetItem={resetItem} />
			</div>
		</>
	);
}

export default ListeOffresTable;
